.item-container{
  width:100%;
  height:100%;
  position: relative;
  
}
.item-container:nth-child(13),
.item-container:nth-child(12),
.item-container:nth-child(11),
.item-container:nth-child(10),
.item-container:nth-child(9),
.item-container:nth-child(8),
.item-container:nth-child(7),
.item-container:nth-child(6),
.item-container:nth-child(5),
.item-container:nth-child(3),
.item-container:nth-child(1){
  grid-column: span 2;
}

.item-container:nth-child(16),
.item-container:nth-child(15),
.item-container:nth-child(14),
.item-container:nth-child(4),
.item-container:nth-child(2){
  grid-column: span 2;
  grid-row: span 2;
}
@media only screen and (min-width: 1500px){ 
  .item-container:nth-child(14),
  .item-container:nth-child(13){
    grid-row: span 3;
  }
}
.image{
  width:100%;
  height:100%;
  overflow: hidden;
}

.item-container {
  display: inline-block;
  overflow: hidden;

  /*&:hover {
    z-index: 10;
    transition: 1s;
  }

  &:hover .image-infotip {
    display: block;
    transition: 1s;
    width: inherit;
  }

  &:hover .item-icons{
    display: block;
    transition: 1s;
    z-index: 30;
  }*/

  img {
    
    &:hover{
      transform:scale(1.1);
      transition: transform 4s cubic-bezier(0.25,0.46,0.45,0.95);
    }
    /*&:hover {
      z-index: 10;
    }

    &:hover .item-icons{
      display: block;
      transition: 1s;
      z-index: 11;
    }*/
  }

.item-icons
{
  color:#FBFBFB;
  opacity: 0.8;
  display: none;
  position: absolute;
  top: 2%;
  left:2%;
}
  .image-infotip {
    display:none;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: #FBFBFB;
    opacity: 0.8;
    color: black;

    .infotip-container {
      font-size: 17px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      width:100%;
      .title {
        grid-column: span 2;
        font-size: 17px;
        padding: 5px 0;
      }

      .infotip-left {
        display: grid;
        grid-column: span 1;
        align-items: center;
        

        .description {
          grid-column: span 2;
          font-size: 12px;
          word-break: break-word;
        }

        .channel-location {
          grid-column: span 2;
          font-size: 12px;
        }
      }

      .infotip-right {
        font-size: 12px;
        display: grid;
        grid-column: span 1;
        align-items: center;



        .author {
          padding-bottom: 3px;
        }
        .location-and-date{
          grid-column: span 1;
          display:grid;
          .location {
            padding-top: 3px;
          }
  
          .date-added {
            padding: 3px 0;
          }
        }
        
      }
    }

    .infotip-button {
      font-size: 13px;
      text-align: left;
      width: 100%;
      padding: 5px 0;
      opacity: 1;
      border: none;
      text-decoration: none;
    }
  }
  .linkContainer {
    background-color: #DCDAD5;
    width: 100%;
    padding: 1% 0;
  }
}

.content-headline {
  display: flex;
  justify-content: flex-start;
}

