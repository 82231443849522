@import '../../assets/globalVariables.module.scss';

.dropzone-container {
  display: flex;
  flex-direction: column;
  height: 75vh;
  width: 100%;
  min-height: 120px;
  position: relative;
  transition: all 0.15s linear;
  border: none;

  .preview-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    overflow-y: auto;
    margin: 0 auto;
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;


    .preview-box {
      position: relative;
      display: flex;
      align-items: center;
      width: 200px;
      height: 160px;
      background: #fff;
      font-size: 14px;
      flex-direction: column;
      padding: 1rem;
      margin: 8px;
      justify-content: start;
      border-radius: 12px;

      .fileName {
        margin: 2px;
        max-width: 180px;
        word-wrap: break-word;
        max-lines: 3;
        text-overflow: ellipsis;
      }

      .image {
        //height: 100%;
        min-width: 100%;
        object-fit: cover;
        border-radius: 4px;
        //margin-left: 5px;
        //margin-right: 5px;
      }

      .iconBox {
        margin-left: auto !important;
        position: relative !important;
        z-index: 9999;
      }

      .iconButton {
        position: absolute !important;
        left: -25px !important;
        top: -25px !important;
      }
      .iconFont {
        font-size: 1.5rem !important;
      }
    }
  }

  /* width */
  .preview-container::-webkit-scrollbar {
    width: 10px;

  }

  /* Track */
  .preview-container::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 15px;
    margin: 0.5rem 0;
  }

  /* Handle */
  .preview-container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 15px;
  }

  /* Handle on hover */
  .preview-container::-webkit-scrollbar-thumb:hover {
    background: #656565;
  }

  .error-box {
    font-size: 18px;
    background-color: white;
    color: red;
  }

  .submit-label {
    display: flex;
    position: relative;
    margin: 30px auto;
    font-family: "Helvetica", sans-serif;
    font-size: 20px;
    font-weight: 100;
    color: white;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;

    .label-text {
      cursor: pointer;
      margin: auto 0;
      color: $brandColor;
    }

    .MuiCollapse-wrapperInner{
      display: flex;
      align-items: center;
    }

    .upload-icon {
      width: 1em;
      height: 1em;
      vertical-align: bottom;
      margin: 0 12px;
      color: $brandColor;
    }
  }

  .dropzone-label {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Helvetica", sans-serif;
    font-size: 40px;
    font-weight: 300;
    color: white;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    cursor: pointer;

    .dropzone-inputLabel {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      font-family: "Helvetica", sans-serif;
      font-size: 40px;
      font-weight: 300;
      color: white;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      cursor: pointer;
    }

    .upload-icon {
      width: 2em;
      height: 2em;
      vertical-align: bottom;
      margin: 0 12px;
      color: $brandColor;

    }
  }
}
