@import '../../assets/globalVariables.module.scss';

.channel-button {
    font-family: 'DM Sans Medium', sans-serif;
    color: #464646;
    padding: 8px 16px;
    line-height: 1.75;
    background-color: white !important;
    border: 0;
    cursor: pointer;
    margin: 8px;
    display: inline-flex;
    outline: 0;
    position: relative;
    box-shadow: -0.5px -0.5px 0.5px 0.5px rgba(0,0,0,0.19), 0.5px 0.5px 0.5px 0.5px rgba(0,0,0,0.19)
}

.channels-container {
    display: inline-block;
    padding: 1% 15% 1%;
    margin-top: 1%;
    margin-bottom: 0.5%;
    border-bottom: 1px solid rgb(232, 232, 232);
    margin: auto;
    width: 68%;
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(236,236,236,1) 0%, rgba(232,232,232,1) 48%, rgba(199,199,199,1) 100%, rgba(163,163,163,1) 100%);
    //TODO: show them if there are any objections
    //background: linear-gradient(78deg, rgba(30,30,30,1) 0%, rgba(53,53,53,1) 7%, rgba(217,217,217,1) 10%, rgba(122,122,122,1) 16%, rgba(146,146,146,1) 22%, rgba(175,175,175,1) 23%, rgba(164,164,164,1) 25%, rgba(148,148,148,1) 28%, rgba(137,137,137,1) 30%, rgba(236,236,236,1) 31%, rgba(236,236,236,1) 37%, rgba(208,208,208,1) 38%, rgba(235,235,235,1) 43%, rgba(198,198,198,1) 47%, rgba(234,234,234,1) 50%, rgba(235,235,235,1) 58%, rgba(233,233,233,1) 60%, rgba(232,232,232,1) 63%, rgba(231,231,231,1) 64%, rgba(235,235,235,1) 65%, rgba(213,213,213,1) 71%, rgba(225,225,225,1) 71%, rgba(224,224,224,1) 72%, rgba(91,91,91,1) 73%, rgba(219,219,219,1) 78%, rgba(236,236,236,1) 81%, rgba(212,212,212,1) 86%, rgba(204,204,204,1) 94%, rgba(0,0,0,0.6418942577030813) 100%, rgba(199,199,199,1) 100%, rgba(163,163,163,1) 100%);
    //background: linear-gradient(78deg, rgba(30,30,30,1) 7%, rgba(217,217,217,1) 10%, rgba(198,198,198,1) 13%, rgba(225,225,225,1) 18%, rgba(146,146,146,1) 22%, rgba(175,175,175,1) 23%, rgba(148,148,148,1) 28%, rgba(137,137,137,1) 30%, rgba(236,236,236,1) 31%, rgba(53,53,53,1) 32%, rgba(236,236,236,1) 37%, rgba(204,204,204,1) 41%, rgba(235,235,235,1) 43%, rgba(231,231,231,1) 44%, rgba(234,234,234,1) 50%, rgba(208,208,208,1) 56%, rgba(212,212,212,1) 57%, rgba(235,235,235,1) 58%, rgba(122,122,122,1) 60%, rgba(233,233,233,1) 60%, rgba(232,232,232,1) 63%, rgba(235,235,235,1) 65%, rgba(231,231,231,1) 66%, rgba(213,213,213,1) 71%, rgba(224,224,224,1) 72%, rgba(91,91,91,1) 73%, rgba(219,219,219,1) 78%, rgba(236,236,236,1) 81%, rgba(211,211,211,1) 83%, rgba(164,164,164,1) 97%, rgba(0,0,0,0.6418942577030813) 100%, rgba(199,199,199,1) 100%, rgba(163,163,163,1) 100%);
    justify-content: flex-start;
    align-items: flex-start;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

@media only screen and (min-width:0px) and (max-width:600px),(min-width:600px){
    .channel-button{
        font-size:0.8rem;
    }
}
@media only screen and (min-width:1200px){
    .channel-button{
        font-size:  1rem;
    }
}
@media only screen and (min-width:1460px){
    .channel-button{
        font-size: 1.2rem;
    }
}
@media only screen and (min-width:1605px){
    .channel-button{
        font-size:1.375rem;
    }
}

.planet {
    @extend .channel-button;

    &:hover {
        background-color: $ourPlanet !important;
        transition: 0.8s;
        color: white;
    }
}

.design {
    @extend .channel-button;

    &:hover {
        background-color: $design !important;
        transition: 0.8s;
        color: white;
    }
}

.music {
    @extend .channel-button;

    &:hover {
        background-color: $music !important;
        transition: 0.8s;
        color: white;
    }
}

.hobby {
    @extend .channel-button;

    &:hover {
        background-color: $hobbies !important;
        transition: 0.8s;
        color: white;
    }
}

.military {
    @extend .channel-button;

    &:hover {
        background-color: $military !important;
        transition: 0.8s;
        color: white;
    }
}

.news {
    @extend .channel-button;

    &:hover {
        background-color: $news !important;
        transition: 0.8s;
        color: white;
    }
}

.famous {
    @extend .channel-button;

    &:hover {
        background-color: $famous !important;
        transition: 0.8s;
        color: white;
    }
}

.sport {
    @extend .channel-button;

    &:hover {
        background-color: $sport !important;
        transition: 0.8s;
        color: white;
    }
}

.health {
    @extend .channel-button;

    &:hover {
        //color will be changed
        background-color: $health !important;
        transition: 0.8s;
        color: white;
    }
}

.transport {
    @extend .channel-button;

    &:hover {
        background-color: $transport !important;
        transition: 0.8s;
        color: white;
    }
}

.artsCulture {
    @extend .channel-button;

    &:hover {
        background-color: $artAndCulture !important;
        transition: 0.8s;
        color: white;
    }
}

.foodDrink {
    @extend .channel-button;

    &:hover {
        //color will be changed
        background-color: $foodAndDrinks !important;
        transition: 0.8s;
        color: white;
    }
}

.techScience {
    @extend .channel-button;

    &:hover {
        background-color: $techAndScience !important;
        transition: 0.8s;
        color: white;
    }
}

.community {
    @extend .channel-button;

    &:hover {
        background-color: $community !important;
        transition: 0.8s;
        color: white;
    }
}

.junior {
    @extend .channel-button;

    &:hover {
        //color will be changed
        background-color: $junior !important;
        transition: 0.8s;
        color: white;
    }
}

.fashion {
    @extend .channel-button;

    &:hover {
        background-color: $fashion !important;
        transition: 0.8s;
        color: white;
    }
}

$breakpoint-burger-menu: 830px;
@media (min-width: $breakpoint-burger-menu) {
  
}