@import "../../assets/globalVariables.module.scss";

.page-container {
    font-family: "DM Sans Medium", sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 98%;
    margin: auto;
    .chamnel-info-container {
        margin: 0 auto;
        width: 95%;
        background: purple;
        border-radius: 0 0 10px 10px;
        display: flex;
        flex-wrap: row;
        justify-content: left;
        padding: 50px 30px;
        color: purple;
    }
    .channel-info-button {
        font-family: "DM Sans Medium", sans-serif;
        color: inherit;
        border: 0;
        cursor: pointer;
        outline: 0;
        position: relative;
        margin: 0 8px 0 0;
        border-radius: 25px;
        -webkit-box-shadow: 1px 3px 7px 2px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 1px 3px 7px 2px rgba(0, 0, 0, 0.2);
        box-shadow: 1px 3px 7px 2px rgba(0, 0, 0, 0.2);
        &:active {
            transform: translateY(1px);
        }
    }
    .main-color-button {
        background: white;
    }
    .follow-button-style {
        background-color: inherit;
        border: 1px solid white;
        color: white;
    }
    .add-to-set-button {
        padding: 6px 16px;
        font-size: 1rem;
        line-height: 1.15;
    }
    .icon-buttons {
        border-radius: 50px;
        width: 40px;
        height: 40px;
        font-size: 1.6rem;
        .fbColor {
            color: #1f5a92;
        }
        .twColor {
            color: #2694dd;
        }
        .inColor {
            color: #de3684;
        }
    }
    .share-button {
        color: #585858;
    }

    .headline {
        text-align: left;
        font-size: 1.1rem;
    }
    .content-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        margin-top: 1%;

        .set-container {
            width: 25%;
            display: flex;
            flex-direction: column;
            max-height: 50vw;
            overflow: auto;
            padding-top: 3px;
            margin-left: 0;
            margin-right: 10px;
            padding-bottom: 0.5rem;
            .set-container-image-container {
                width: 100%;
                height: auto;
                margin-bottom: 1rem;
            }
            .set-container-image {
                border-radius: 10px;
                width: 95%;
                height: auto;
                margin-bottom: 10px;
                margin: 0 auto;
                position: relative;
            }
            .set-container-image-cover {
                @extend .set-container-image;
                border: 5px solid #e2e2e2;
            }
            .image-clickable {
                cursor: pointer;
                &:active {
                    transform: translateY(1px);
                }
            }
            img{
                width: 100%;
            }
        }

        .gallery-view-set-container{
            width: 100%;
            padding: 1rem;
            max-height: 400px;
            overflow: auto;

            .set-container-image-container{
                cursor: pointer;
            }
        }

        .social-container {
            display: flex;
            justify-content: right;
            font-size: 1rem;
            width: auto;
            margin-left: auto;
            text-align: left;
        }

        .image-info-conainer {
            display: flex;
            flex-direction: column;
            width: 85%;

            .image-container {
                display: flex;
                flex-direction: row;
                width: 100%;
                justify-content: flex-start;
                align-items: flex-start;
                align-items: center;
                padding-top: 3px;
                .image-wrapper {
                    margin: auto;
                    margin-top: 0;
                }
                .image-style-fullWidht {
                    max-height: 50vw;
                    max-width: 55vw;
                    display: block;
                }
                .full-width{
                    min-width: 300px;
                    width: 700px;
                    max-width: 50vw;
                    height: 400px;
                }
                
                .image-description-fullscreen {
                    margin-bottom: auto;
                    margin-left: 10px;
                    width: 30%;
                    border-radius: 15px;
                    padding: 1rem;
                }
                .external-link-button {
                    background-color: white;
                    border: none;
                    outline: none;
                    font-size: 1rem;
                    border-radius: 25px;
                    padding: 7px 25px;
                    margin-top: 1rem;
                    margin-left: 10px;
                    margin-right: 10px;
                    margin-bottom: 10px;
                    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.3);
                    &:active {
                        transform: translateY(1px);
                    }
                }
                img{
                    max-height: 50vw;
                    max-width: 55vw;
                }
            }
            .tag-container {
                margin-top: 10px;
                margin-bottom: 30px;
                overflow-x: hidden;
                overflow-y: auto;
                max-height: 20rem;
                .tags {
                    font-family: "DM Sans Medium", sans-serif;
                    color: #707070;
                    padding: 3px 16px;
                    font-size: 1rem;
                    line-height: 1.15;
                    border: 0;
                    display: inline-flex;
                    outline: 0;
                    position: relative;
                    margin: 0 8px;
                    border-radius: 25px;
                    background-color: inherit;
                    border: 1px solid #707070;
                    margin-bottom: 7px;
                    float: left;
                }
            }
            .price-box {
                font-family: "DM Sans Bold", sans-serif;
                font-weight: bold;
                border-radius: 15px;
                background: #f1f1f1;
                font-size: 1.2rem;
                display: flex;
                flex-direction: row;
                padding: 20px 20px;
                margin: 30px 0px 5px 0px;
                -webkit-box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.3);
                -moz-box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.3);
                box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.3);

                .price {
                    width: 50%;
                    font-size: 1rem;
                    line-height: 1.1;
                    margin: auto;
                    margin-left: 5px !important;
                    text-align: left;
                }
                .sign-in {
                    color: $brandColor;
                    cursor: pointer;
                    font-weight: bold;
                }
            }
            

            .info-conainer {
                display: flex;
                flex-direction: row;
                .info {
                    display: flex;
                    flex-direction: column;
                    float: left;
                    width: 50%;
                }
            }
          
        }
        .subscription-box {
            @extend .price-box;
            flex-direction: column !important;
            background: #e7e7e7;
            padding: 0;
            .subscription-box-info {
                background-color: #f1f1f1;
                border-top-left-radius: 15px;
                border-top-right-radius: 15px;
                padding: 30px 10px;
                color: #4d4d4d;
            }
        }
        .price-box-editorial
        {
            @extend .price-box;
            flex-direction: column !important;
            background: #e7e7e7;
            .editorial-rate
            {
                padding:10px;
                display: flex;
                flex-direction: row;
            }
        }
        .owned-box-info
        {
            @extend .price-box;
            margin:auto;
            padding: auto;

            .owned-text
            {
                margin: auto;
            }
            .download-button {
                font-family: "DM Sans", sans-serif;
                font-weight: bold;
                color: inherit;
                padding: 8px 15px;
                font-size: 1rem;
                line-height: 1.1;
                border: 0;
                outline: 0;
                position: relative;
                margin-right: 15px;
                margin-left: 10px;
                border-radius: 25px;
                color: white;
                background: $brandColor;
              }
        }

    }
    .subscriptio-add-to-cart {
        @extend .add-to-cart;
        margin: 10px auto;
        max-width: 40%;
    }
    
    .description-container {
        text-align: left;
        max-height: 10rem;
        overflow: auto;
        font-size: 0.9rem;
    }
    .info-text {
        text-align: left;
    }
    .font-weight-normal {
        font-weight: 200;
    }
}
.Article {
    width: 100%;
}
.imageContainerFull {
    width: 100% !important;
}
.description-box-single-content {
    margin-top: 30px;
}
.add-comment-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    .add-comment-button {
        background: #e2e2e2;
        padding: 10px 3px 10px 5px;
        color: #585858;
        width: 15rem;
        font-size: 1rem;
        border: none;
        border-radius: 10px;
        outline: none;
        margin-right: auto;
        margin-bottom: 10px;

        &:active {
            transform: translateY(1px);
        }
    }
    .MuiFormControl-root {
        width: 100%;
    }
    .add-comment-form {
        display: flex;
        transition: visibility 0.3s linear, opacity 0.3s linear;
        background-color: #e2e2e2;
        margin-bottom: 30px;
        width: 50%;
        border-radius: 15px;
        padding: 1%;
        .MuiInputBase-root::before {
            border: none;
        }
        .MuiInputBase-input::after {
            outline: none;
            display: none;
        }
        .MuiInputBase-input {
            color: #585858;
        }
        .post-comment {
            background-color: white;
            border: none;
            outline: none;
            font-size: 1rem;
            border-radius: 25px;
            padding: 7px 15px;
            float: right;
            margin-top: auto;
            margin-left: 10px;
            margin-right: 10px;
            box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.3);
            &:active {
                transform: translateY(1px);
            }
        }
    }
    .comment-box-show {
        visibility: visible;
    }
}

.comment-box {
    display: flex;
    flex-direction: column;
    width: 95%;

    .comments {
        overflow-x: hidden;
    }
    .comments-details{
        width: 50%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .comments-details span{
        font-size: 1rem;
    }
    .alling-left {
        text-align: left;

    }
    .sign-in {
        color: $brandColor;
        cursor: pointer;
        font-weight: bold;
    }
}
.comment {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    .MuiAvatar-root {
        z-index: 10;
    }
    .name-pill {
        font-size: 1.3rem;
        font-weight: bold;
        margin-bottom: 0.3rem;
        margin-top: 0;
        text-align: left;
    }

    .date-pill{
        font-size: 0.7rem;
        margin-bottom: 0.8rem;
        margin-top: 0;
        text-align: left;
        color: rgba(0, 0, 0, 0.734);
    }

    .name-and-tag {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin-bottom: 10px;
        
        .report-button-container {
            margin-left: auto;
            margin-right: 2px;
            margin-top: auto;
        }
        .report-button {
            font-size: 1rem;
            padding: 5px 7px;
        }
        .nametag {
            display: flex;
            flex-direction: row;
           
            .user-pic {
                color: #b2b2b2;
                border-radius: 50%;
                width: 5rem;
                height: 5rem;
                padding: auto;
                font-size: 4rem;
                background-color: lightgray;
            }
        }
        .fa-5x {
            font-size: inherit !important;
        }
    }

    .comment-container {
        text-align: left;
        font-size: 1.1rem;
        color: rgba(0, 0, 0, 0.734);
    }

    .name-and-comment{
        display: block;
        margin-left: 1rem;
    }
}
.article-text {
    text-align: justify;
    text-justify: inter-word;
}
.audio-player {
    display: flex;
    flex-direction: column;
    text-align: left;
    background-color: lightgrey;
    padding: 10px;
    border-radius: 15px;
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.3);

}
.react-audio-player {
    outline: none;
    width: 100%;
}
.file-name {
    margin-bottom: 10px;
}
.loader-spinner {
    margin-top: 12%;
}

#water-mark-observer {
    background-repeat: no-repeat !important;
}

@media only screen and (max-width: 1200px) {
    .image-container {
        display: flex !important;
        flex-direction: column !important;
    }
    .content-container {
        display: flex !important;
        flex-direction: column !important;
    }
    .image-description-fullscreen {
        width: 100% !important;
        margin-top: 30px !important;
    }
    .imageContainerPartial {
        width: 100% !important;
        padding-right: 0 !important;
    }
    .set-container {
        width: 98% !important;
        margin: auto !important;
        height: auto !important;
        display: flex !important;
        flex-direction: row !important;
    }
    .set-container-image {
        width: auto !important;
        margin: 0 10px !important;
        min-width: 12vw !important;
        max-height: 12vw !important;
    }
    .image-style-fullWidht {
        height: auto !important;
        max-height: none !important;
        width: 100% !important;
        max-width: 100% !important;
    }
    .price-box {
        max-width: 70% !important;
    }
}

@media only screen and (max-width: 800px) {
    .name-pill {
        font-size: 0.8rem !important;
        left: -1.5rem !important;
        padding: 0.3rem 0.6rem !important;
        padding-left: 1.5rem !important;
        border-radius: 1rem !important;
    }
    .user-pic {
        width: 3rem !important;
        height: 3rem !important;
        font-size: 2rem !important;
    }
}
.comment-skeleton{
    margin: 1rem;
}

.comment-skeleton-user{
    display: flex;
    margin-bottom: 0.5rem;
    justify-content: flex-start;
    align-items: flex-end;
}

.photo-details-image-container-actions{
    width: 100%;
    height: 99.4%;
    position: absolute;
    top: 0;
    display: flex;
    align-items: end;
    flex-direction: column;
    justify-content: space-between;

    .hidden{
        visibility: hidden;
    }
    .visible{
        visibility: visible;
    }
}
.icon-button{
    width: 20px;
    height: 35px;
}
.add-to-light-box{
    width: 130px;
    height: 40px;
    border: 0;
    padding: 0.3rem 0.4rem;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.add-to-cart-icon-button{
    width: 130px;
    height: 40px;
    border: 0;
    padding: 0.3rem 0.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    bottom: 4px;
    right: 0;
}

.hide{
    display: none !important;
}

.show{
    display: flex !important;
}

.add-to-cart-icon-button{
    width: 40px;
    height: 40px;
    color: black;
}

.add-to-cart {
    width: 150px !important;
    font-family: "DM Sans", sans-serif;
    font-weight: bold;
    color: inherit;
    padding: 8px 15px;
    font-size: 1rem;
    line-height: 1.1;
    border: 0;
    outline: 0;
    position: relative;
    margin-right: 15px;
    margin-left: 10px;
    border-radius: 25px;
    color: white;
    background: $brandColor;
    cursor: pointer;
    &:active {
        transform: translateY(1px);
    }
}

.editorial-download-button {
    @extend .add-to-cart;
}