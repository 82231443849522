@import '../../assets/globalVariables.module.scss';

.login-modal{
    width: 380px !important;
	height: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
    .close-button-login {
        border: none;
        outline: none;
        font-size: 1.3rem;
        width: 30px;
        margin-left: auto;
        margin-right: 20px;
        margin-top: 30px;
        background: white;
        cursor: pointer;
        .icon-button-login {
            color: $brandColor;
        }
    }
}