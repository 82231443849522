@import "../../../assets/globalVariables.module.scss";

.smooth-image {
  height: auto;
  max-width: 100%;
  transition: opacity 1s;
}

.image-visible {
  opacity: 1;
}

.image-hidden {
  opacity: 0;
}

.smooth-no-image {
  max-height: 100%;
  max-width: 100%;
  background-position: center;
}

.image-box {
  position: relative;
  margin-bottom: auto;
  border-radius: 0.8rem;
}

.price-box {
  font-family: "DM Sans Bold", sans-serif;
  font-weight: bold;
  border-radius: 15px;
  font-size: 1.2rem;
  display: flex;
  flex-direction: row;
  padding: 20px 20px;
  margin: 0px 0px 5px 0px;

  .price {
      font-size: 1rem;
      line-height: 1.1;
      margin: auto;
      margin-left: 5px !important;
      text-align: left;
  }
  .sign-in {
      color: $brandColor;
      cursor: pointer;
      font-weight: bold;
  }
}

.price-box-editorial-content
{
    @extend .price-box;
    flex-direction: column !important;
    .editorial-rate
    {
        padding:10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}
