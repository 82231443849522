@import "../../assets/globalVariables.module.scss";

.navigation-bar-container {
  padding-top: 6px;
  background-color: white;
  border-bottom: 1px solid rgb(232, 232, 232);

  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgb(232, 232, 232);
    height: 50px;
    padding: 1%;
    -webkit-box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.14);
    box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.14);
    .search-bar {
      width: auto;
      margin: auto 1rem auto 0;
    }

    .logo-container {
      width: 280px;
      height: auto;
      text-align: center;
      cursor: pointer;
    }
    .logo-image {
      width: 100%;
    }
    /*.logo-link {
      text-decoration: none;
      color: black;
    }*/
    .search-and-menu-container{
      width: 30%;
      display: flex;
      justify-content: flex-end;
    }
    .options {
      display: flex;
      align-items: center;
      width: 30%;
      margin-top: 6px;
    }

    .buttonDiv {
      display: flex;
      width: auto;
    }

    .burger-menu {
      display: none;
    }

    .navigation-link {
      font-family: "DM Sans", sans-serif;
      text-decoration: none;
      color: black;
      font-size: 1rem;
      align-items: center;
      display: flex;
      letter-spacing: -1px;
      text-transform: none;
      cursor: pointer;
    }

    .signup-signin-group {
      display: inline-block !important;

      &:focus {
        outline: none !important;
      }
    }

    .item-count {
      font-size: 10px;
      font-weight: bold;
      border-radius: 20px;
      background-color: rgb(120, 248, 120);
      padding: 3px;
      bottom: 12px;
    }
  }
}

.nav-link-option {
  text-decoration: none !important;
}

.bm-item {
  display: inline-block;
  text-decoration: none;
  margin-bottom: 10px;
  color: $brandColor;
}

.bm-burger-button {
  position: sticky;
  margin-top: 12px;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: black;
}
#react-burger-cross-btn{
  width: 35px !important;
  height: 35px !important;
  top: 5px !important;
  left: -5px !important;
}
/* Color/shape of close button cross */
.bm-cross {
  background: black;
  height: 35px !important;
}

/* General sidebar styles */
.bm-menu {
  background: white;
  padding: 2.5em 1.5em 0;
  font-size: 1.25em;
}

.bm-menu-wrap {
  position: fixed;
  top: 0;
  right: 0;
}

/* Wrapper for item list */
.bm-item-list {
  color: black;
  font-size: 1.25em;
}

$breakpoints: (
  phone: 640px,
  tablet: 768px,
  desktop: 1024px,
) !default;

@media only screen and (max-width: 1600px) {
  .language-select {
    font-size: 0.5rem !important;
    .flag-select__option__icon {
      width: 1rem !important;
      height: 0.9rem !important;
    }
  }
}
@media only screen and (max-width: 1024px) {
  .navigation-bar-container {
    .header-container {
      .options {
        display: none;
      }

      .burger-menu {
        display: flex;
        width: 10%;
        justify-content: flex-end;
        align-items: center;
    }
    }
  }
}
.navigation-profile {
  color: #b2b2b2;
  border-radius: 50%;
  padding: 2px 5px;
  margin-right: 10px;
  border: 0;
  cursor: pointer;
  margin: 8px;
  display: inline-flex;
  outline: 0;
  // border-radius: 25px;
  -webkit-box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.3);
}
#userInfo {
  pointer-events: none;
  background: #e9e9e9;
}
.language-select {
  button {
    border: 0;
    outline: 0;
  }
  .flag-select__option__icon {
    border-radius: 50%;
    margin-right: 5px;
    float: right;
    object-fit: cover;
    width: 1.3em;
    height: 1.1em;
    top: 0.4em;
    position: relative;
  }
  button .flag-select__option__label {
    float: left;
    margin-right: 5px;
    margin-top: 6px;
  }
  ul .flag-select__option__label {
    float: left;
    margin-top: 5px;
    margin-right: 2px;
  }
}

.profile-image-container {
  display: inline-flex;
  width: 50px;
  border-radius: 50%;
  height: 50px;
}
.profile-image {
  width: 100%;
  border-radius: 50%;
}

.menuItem,
.userEmail,
.menuItemWidthSmall {
  font-family: "DM Sans", sans-serif;
  letter-spacing: -1px;
  text-transform: none !important;
  color: black !important;
  min-width: 200px !important;
  max-width: 200px !important;
  padding: 0.5rem;
  font-size: 1rem;
  text-align: center !important;
  margin: 0;
  border-radius: 15px !important;
  text-decoration: none !important;
}

.textLeft {
  text-align: left !important;
}

.widthSm {
  min-width: 90px !important;
  max-width: 90px !important;
}

.userEmail {
  color: $brandColor !important;
  font-size: 0.9rem !important;
  inline-size: 220px;
  overflow-wrap: break-word;
}

.menuItem:hover {
  color: white !important;
  cursor: pointer;
  font-weight: bold;
  background-color: $brandColor !important;
}

@media (min-width:344px)  {
  .header-container{
    justify-content: space-between;
  }
  
}