@import "../../assets//globalVariables.module.scss";

.results-container{
  float:left;
  margin-left: 20px;
}
.download-table-heading {
  width: 100%;
}
.refetch-container {
  width: 98%;
  margin: 0 1%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  .upload-white-button {
    min-width: 170px;
    font-family: 'DM Sans Medium', sans-serif;
    font-size: 1rem;
    color:black;
    border: 0;
    cursor: pointer;
    border-radius: 15px;
    background-color: white;
    text-transform: none;
  }
}

tbody tr:nth-child(odd)
{
  background: #efefef;
}
.add-more-cell
{
  padding-top:0;
}
thead{
  box-shadow: 1px 3px 7px 2px rgba(0, 0, 0, 0.2);
}
.additional-controlls-container
{
  display: flex;
  flex-direction: row;
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  .fisters-container
  {
    max-width: 350px;
  }
  .type-select-container
  {
    margin-left: auto;
    max-width: 400px;
  }
  .preffered-currency-container
  {
    margin-left: auto;
    max-width: 350px;
  }
}
.search-field
{
  width:85%
}
.search-placeholder
{
  width: 90px;
}
.currency-select
{
  font-family: 'DM Sans Medium', sans-serif;
  font-size: 1rem;
  color:black;
  border: 0;
  cursor: pointer;
  outline: 0;
  margin-left: 10px;
  padding: 3px 8px;
  border-radius: 25px;
  background:white;
  -webkit-box-shadow: 1px 3px 7px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 3px 7px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 3px 7px 2px rgba(0, 0, 0, 0.2);
  .select-currency-field::before
  {
    border-bottom: 0px white;
    content:none
  }
}

.max-width-headline
{
  max-width: 150px;
}
.placeholder-cell
{
  width: 60px;
}
.approved
{
  color:#1b9233;
}
.pending
{
  color:#c7c763
}
.rejected
{
  color:#a13412;
}
.incomplete {
  color: #949699;
}
.img-container
{
  &:hover .image-preview {
    display: block;
    transition: 1s;
    width: inherit;
  }
}

.thumbnail
{
  width: 50px;
  min-height: 50px;
}
.preview
{
  width: 300px;
  position: absolute;
  border-radius: 25px;
  left:80px;
}
.MuiAutocomplete-popper{
  min-width: fit-content !important;
  padding: 5px !important;
}

.download-button {
  width: 140px;
  height: 35px;
  padding: 0 20px 0 20px;
  font-size: 15px;
  background-color: $brandColor;
  color: white;
  font-family: "Open Sans Condensed";
  border-radius: 25px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  border: none;
}

.icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 0.5rem;
}

.MuiBox-root{
  padding-top: 0 !important;
}
