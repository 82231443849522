@import '../../assets/globalVariables.module.scss';

.headline{
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
}


.add-button{
  border-radius: 15px;
  font-size: 1rem;
  background-color: $brandColor !important;
  color: white !important;
  width: auto !important;
  padding: 0.8rem 1rem;
  border: none;
  cursor: pointer;
  margin-right: 1rem;
}

.headline-title{
  font-size: 1.5rem;
  font-weight: bold;
  margin-left: 1rem;
}

.light-box-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
}

.light-box-card {
  display: flex;
  flex-direction: column;
  min-width: 350px;
  width: 15%;
  height: fit-content;
  margin: 1rem;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.cover-image-container {
  display: flex;
  width: 100%;
  height: 200px;
}

.cover-image {
  width: 100%;
  object-fit: cover;
  cursor: pointer;
  &:hover{
    transform: scale(1.05, 1.05);
    transition-duration: 1s;
  }
}

.light-box-title {
  font-size: 1.2rem;
  text-align: center;
  word-break: break-all;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  padding: 0 1rem;
}

.light-box-card-actions {
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-bottom: 1rem;
}

.light-boxes{
  min-height: 720px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.pagination-container{
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}

.light-box-contents-container{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.light-box-content-container{
  position: relative;
  margin: 0.3rem;
  min-width: 200px;
  max-width: 400px;
  height: 300px;
  flex: 0.5;
  cursor: pointer;
  &:hover{
    box-shadow: rgba(0, 0, 0, 0.16) 10px 8px 10px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  }

  &:hover .light-box-content-image-actions-container{
    display: flex;
  }
}

.light-box-image-container{
  width: 100%;
  height: 100%;
}

.light-box-image{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.light-box-content-image-actions-container{
  justify-content: space-around;
  align-items: center;
  position: absolute;
  width: 100%;
  bottom: 0;
  background-color: #0000007c;
  height: 50px;
  display: none;
}

.main-color-button {
  background: white;
}

.channel-info-button {
  font-family: "DM Sans Medium", sans-serif;
  color: inherit;
  border: 0;
  cursor: pointer;
  outline: 0;
  position: relative;
  margin: 0 8px 0 0;

  &:active {
      transform: translateY(1px);
  }
}

.icon-buttons {
  border-radius: 50px;
  width: 40px;
  height: 40px;
  font-size: 1.6rem;
  .fbColor {
      color: #1f5a92;
  }
  .twColor {
      color: #2694dd;
  }
  .inColor {
      color: #de3684;
  }
  .linkColor{
    color: rgb(46, 46, 255);
  }
}

.links{
  text-decoration: none;
  color: black;
}

.image-skeleton{
  border-radius: 0 !important;
  margin: 1rem;
}
