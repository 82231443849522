@import '../../assets/globalVariables.module.scss';

.register-modal{
    max-width: 380px;
	height: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
    font-family: Arial, Helvetica, sans-serif;
    .title{
        font-weight: 400;
    }
    .close-button-register {
        border: none;
        outline: none;
        font-size: 1.3rem;
        width: 30px;
        margin-left: auto;
        margin-right: 20px;
        margin-top: 30px;
        background: white;
        cursor: pointer;
        .icon-button-register {
            color: $brandColor;
        }
    }
}