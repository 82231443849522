@import "../../assets/globalVariables.module.scss";
@import "../../assets/media-queries-breakpoints.scss";

.filters-container {
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    width: 98%;
    margin: 1%;

    .filters-row {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 1%;
        margin-right: 1%;
        width: 85%;

        .filters-components {
            display: flex;
            flex-direction: row;
            position: relative;
            flex-wrap: wrap;
            align-items: baseline;
            gap: 15px;

            .clear-round-button {
                border-radius: 15px;
                margin: 0px 1%;
                color: black !important;
                background-color: white;
                text-transform: none;
                font-size: small;
            }
        }

        .result-search-bar{
            width: 100%;
            justify-content: center;
            display: flex;
            flex-direction: row;
            position: relative;
        }
    }

    .hide-filters-section {
        width: 15%;
        display: flex;
        justify-content: flex-end;
        height: 100%;
        padding: 6px 0;
        margin: 5px;
        .clear-round-button {
            border-radius: 15px;
            margin: 0px 1%;
            color: black !important;
            background-color: white;
            text-transform: none;
            font-size: small;
        }
    }
}

.clear-round-button-show-filters {
    border-radius: 15px;
    background-color: white;
    text-transform: none !important;
    font-size: small;
    margin-left: 50% !important;
    margin-bottom: 35px !important;
}

.date-validation-field {
    text-align: center;
    color: red;
    font-family: "DM Sans Medium", sans-serif;
}

.date-filter-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2%;
}

.location-filter-container {
    min-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2%;
}

.colour-filter-container {
    display: flex;
}

.white-round-button {
    color: black !important;
    border-radius: 20px !important;
    background-color: white !important;
    text-transform: none !important;
    margin: 5px !important;
}

.white-round-button-channel-contex {
    color: black !important;
    border-radius: 20px !important;
    background-color: white !important;
    text-transform: none !important;
    margin: 5px !important;
}

.selected-round-button {
    color: white !important;
    background-color: #bfbfbd !important;
    text-transform: none !important;
    border-radius: 20px !important;
    margin: 5px !important;
}

.date-button-group{
    display: flex;
    flex-direction: row;
}

.MuiPickersToolbar-toolbar {
    background-color: $brandColor !important;
}

.MuiPickersDay-current {
    background-color: $brandColor !important;
}