.imageWrap {
    width: 100%;
    position: relative;
}

.uploadPercentWrap {
    width: 100%;
    position: absolute;
    height: 100%;
    margin: 0px;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
}

.uploadPercent {
    position: absolute;
    left: 1%;
    width: 0;
    background: #0caf95;
    height: 10px;
    border-radius: 5px;
    margin-top: 23%;
}

.layer{
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.7;
    position: absolute;
}

.upload-loader{
    position: absolute;
    top: 35%;
    left: 40%;
}