@import '../../assets/globalVariables.module.scss';
@import '../../assets/media-queries-breakpoints.scss';

.upperBand{
  background-color: $ourPlanet-lighter;
  display: flex;
  flex-direction: column;
  width: 98%;
  margin: 0 1%;
  padding-bottom: 30px;
  align-items: flex-start;
  height: auto;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  .upper-actions{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .chip-container{
    display: flex;
    align-items: center;

    .spanResults{
      color: #7f817e;
      font-size: 1.3rem;
      margin-left: 10px !important;
      margin-top: 30px;
    }
  }

  .gallery-view-container{
    margin-top: 30px !important;
  }
  
  .search-bar{
    margin-top: 20px;
    width: 25%;
    margin-left: 25px;

    @include media(">smalldesktop", "<desktop") {
      width: 35%;
    }

    @include media(">tablet", "<smalldesktop") {
      width: 40%;
    }

    @include media(">phone", "<tablet") {
      width: 50%;
    }
  }
}
