@import '../../assets/globalVariables.module.scss';
@import '../../assets//media-queries-breakpoints.scss';

.cart-table-container
{
    border-radius: 10px;
    margin: 2% auto;
    box-shadow: 1px 3px 7px 2px rgba(0, 0, 0, 0.2);
    
    .cart-table-heading
    {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        background: $brandColor;
        color:white;
        padding: 10px;
        h2{
            margin: 0
        }
    }
    .cart-table-datatable
    {
        border-collapse: collapse;
        width:100% !important;
        .cart-table-cells{
            padding: 10px 0;
        }
        thead{
            box-shadow: none;
        }
        .column-content
        {
            max-width: 150px;
            padding: 10px 0;
        }
    }
    
    .remove-button:active
    {
        transform: translateY(1px);
    }
}

.cart-table-total{
    padding: 15px;
    background-color: #FFFFFF;
}
.cart-table-total-gray{
    padding: 15px;
    background-color: #EFEFEF;
}

.pill-button
{
    background: white;
    padding: 0px 16px;
    font-size: 1.1rem;
    line-height: 1.75;
    font-family: 'DM Sans Medium', sans-serif;
    display: inline-flex;
    cursor: pointer;
    text-transform: none;
    outline: 0;
    border-radius: 25px;
    margin: 10px;
    &:active
    {
        transform: translateY(2px);
    }
    &:hover {
        color: 'white';
        background: 'black';
    }

}

.cart-step-navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.next-page
{
    @extend .pill-button;
    background:$brandColor;
    border:0;
    color:white;
    box-shadow: 1px 3px 7px 2px rgba(0, 0, 0, 0.2);
}
.back-button
{
    @extend .pill-button;
    box-shadow: 1px 3px 7px 2px rgba(0, 0, 0, 0.2);
    border:0;
}
.button-hidden {
    display: none;
}
.button-text
{
    display: flex;
    padding-top: 3px;
    align-items: center;
}

.img-container
{
  &:hover .image-preview {
    display: block;
    transition: 1s;
    width: inherit;
  }
}

.thumbnail
{
  width: 50px;
  min-height: 50px;
}
.preview
{
  max-width: 300px;
  position: absolute;
  border-radius: 10px;
  left:17%;
  z-index: 200;
}
.cart-payment-container
{
    position: relative;
    margin: 2% auto;

    .loading-box {
        width: 100%;
        height: 100%;            
        position: absolute;
        top: 0;
        left: 0;
        opacity: 1;
        z-index: 19;
    }
    
    .cart-paypal-checkout
    {
        width: 100%;
        border-radius: 10px;
        background: #05297d;
        color: white;
        border: none;
        outline: none;
        padding: 10px 0;
        margin-bottom: 20px;
        font-size: 1.1rem;
        h2{
            margin:0;
        }
        &:active
        {
            transform: translateY(1px);
        }

    }
    .MuiAccordion-rounded
    {
        border-radius: 10px !important;
    }
    #panel1c-header
    {
        background:$brandColor;
        text-align: center;
        width:auto;
        border-radius: 10px;
    }
    .MuiAccordionSummary-root.Mui-expanded
    {
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
        max-height: 48px;
        text-align: center;
    }
    
    .title
    {
        text-align: center;
        width: 100%;
    }

    #panel1c-content{
        border-bottom-left-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
    }
    .accordion-container
    {
        padding:10px 20px;
        background: #e9e9e9;
        border-bottom-left-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
        display:flex;
        text-align: left;
        flex-direction: column;
        justify-content: center;
        margin: 0px auto;

        .save-button{
            color:white;
            background: $brandColor;
            font-size: 1rem;
            line-height: 1.5;
            font-family: 'DM Sans Medium', sans-serif;
            border: 0;
            cursor: pointer;
            display: inline-flex;
            outline: 0;
            border-radius: 25px;
            &:active
            {
                transform: translateY(2px);
            }
        }

    }
    
        .custom-accordion
        {
            box-shadow: none;
            border-bottom: 1px solid gray !important;
            border-radius: 0 !important;
            .MuiCollapse-wrapper
            {
                background:  #e9e9e9;
            }
           
        }
        .inner-accordion
        {
            background:  #e9e9e9;
            padding: 0 !important;
            .panel1c-content
            {
                padding-left: 30px;
            }
        }
        .years
        {
            float: left;
            color: gray;
            background: inherit;
            border: none;
            outline: none;
            font-size: 1.1rem;
            margin-left: 10px;
            margin-bottom: 10px;
            max-height: 200px;
        }
            .gray-color
        {
            color:gray;
        }
        .StripeElement
        {
            margin: 10px 0 20px 0;
            padding: 10px 0;
            border-bottom: 1px solid gray !important;
        }

    .details-container {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: baseline;
        justify-content: center;

        .detail {
            width: 48%;
            margin: 1%;

            @include media(">phone", "<desktop") {
                width: 100%;
                margin: 1%;
            }
        }
    }
}

.pay-button
{
    @extend .pill-button;

    background: $brandColor;
    font-size: 1.3rem;
    color: white;
    float: right;
    border: none;
    box-shadow: 1px 3px 7px 2px rgba(0, 0, 0, 0.2);
}
.empty-cart
{
    @extend .pill-button;
    outline: none;
    border: none;
    box-shadow: 1px 3px 7px 2px rgba(0, 0, 0, 0.2);
}

.download-button{
    @extend .pill-button;
    outline: none;
    border: none;
    box-shadow: 1px 3px 7px 2px rgba(0, 0, 0, 0.2);
}