.gallery-container{
  display:grid;
  grid-template-columns: repeat(6,1fr);
  grid-gap:2vh;
  margin: auto;
  padding:1.3% 0 2%;
  width: 95%;
}
@media only screen and (max-width: 600px) {
  .gallery-container{
    grid-template-columns: repeat(1,1fr);
  }
} 

@media only screen and (min-width: 600px){
  .gallery-container{
    grid-template-columns: repeat(4,1fr);
  }
}

@media only screen and (min-width: 900px) {
  .gallery-container{
    grid-template-columns: repeat(6,1fr);
  }
}
@media only screen and (min-width: 1500px){
  .gallery-container{
    grid-template-columns: repeat(8,1fr);
  }
}
.ads {
    background-color: grey;
    color: white;
}