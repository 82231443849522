.local-content-container {
    width: 15%;
    flex-direction: column;
    margin-right: 5px;
    text-align: center;

    .local-content-heading{
        color: #999;
        font-size: 1.2rem;
        font-family: 'DM Sans Medium', sans-serif;
    }

    .local-content-no-results {
        font-family: 'DM Sans Medium', sans-serif;
        margin-top: 4%;
        font-size: 1rem;
    }
   
    .image-container {
        flex-shrink: 0;
        max-width: 100%;
        max-height: 100%;
    }

    img {
        width: 100%;
        height: 100%;
    }

    .list-item{
        display: flex;
        flex-direction: column;
    }
}
