@import '../../assets/globalVariables.module.scss';

.cookie-container
{
    background: $brandColor !important;
    color: white !important;
    font-size: 1.1rem !important;
    line-height: 1.75 !important;
    font-family: 'DM Sans Medium', sans-serif !important;
}

.pill-button
{
    background: white !important;
    color: $brandColor !important;
    padding: 0px 16px;
    font-size: 1.1rem !important;
    line-height: 1.75 !important;
    font-family: 'DM Sans Medium', sans-serif !important;
    display: inline-flex;
    cursor: pointer;
    text-transform: none;
    outline: 0;
    border-radius: 25px !important;
    margin-left: 10px;
    margin-bottom: 10px;
    &:active
    {
        transform: translateY(1px);
    }
    -webkit-animation: glowing 1500ms infinite;
    -moz-animation: glowing 1500ms infinite;
    -o-animation: glowing 1500ms infinite;
    animation: glowing 1500ms infinite;
}

  @-webkit-keyframes glowing {
    0% { background-color: gray; -webkit-box-shadow: 0 0 3px gray; }
    50% { background-color: #03705e; -webkit-box-shadow: 0 0 40px #03705e; }
    100% { background-color: gray; -webkit-box-shadow: 0 0 3px gray; }
  }
  
  @-moz-keyframes glowing {
    0% { background-color: gray; -moz-box-shadow: 0 0 3px gray; }
    50% { background-color: #03705e; -moz-box-shadow: 0 0 40px #03705e; }
    100% { background-color: gray; -moz-box-shadow: 0 0 3px gray; }
  }
  
  @-o-keyframes glowing {
    0% { background-color: gray; box-shadow: 0 0 3px gray; }
    50% { background-color: #03705e; box-shadow: 0 0 40px #03705e; }
    100% { background-color: gray; box-shadow: 0 0 3px gray; }
  }
  
  @keyframes glowing {
    0% { background-color: gray; box-shadow: 0 0 3px gray; }
    50% { background-color: #03705e; box-shadow: 0 0 40px #03705e; }
    100% { background-color: gray; box-shadow: 0 0 3px $brandColor; }
  }