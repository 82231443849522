@import '../../assets/globalVariables.module.scss';
@import '../../assets//media-queries-breakpoints.scss';

.hidden-tabs
{
    display:none
}
.license-page-display
{
    border-radius: 15px;
    box-shadow: 0px 3px 7px 0px rgba(0,0,0,0.2);
    margin: 2% auto;
    min-height: 250px;
}
.get-license-plans-button
{
    background: white;
    padding: 0px 16px;
    font-size: 1rem;
    line-height: 1.75;
    font-weight: 500;
    font-family: 'DM Sans Medium', sans-serif;
    display: inline-flex;
    outline: 0;
    border:none;
    border-radius: 25px;
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.3);
    &:active
    {
        transform: translateY(2px) translateX(2px);
    }
}
.license-page-headline
{
    margin: 0;
    background:$brandColor;
    color: white;
    padding: 2%;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.license-page-rows
{
    display:flex;
    flex-direction: row;
    padding: 2.5%;

    .license-page-cells
    {
        width:100%;
    }
    &:nth-child(even)
    {
        background: #e3e5e5;
    }
    &:last-child
    {
        border-bottom-left-radius: 15px !important;
        border-bottom-right-radius: 15px !important;
    }   
    
}

#panel1c-header{
    width:auto !important;
}

.page-wrapper {
    width: 60%;
    position: relative;
    margin: 1% auto;

    @include media("<phone") {
        width: 100%;
    }

    @include media(">phone", "<tablet") {
        width: 90%;
    }

    @include media(">tablet", "<desktop") {
        width: 70%;
    }

    @include media(">desktop", "<LGdesktop") {
        width: 50%;
    }
}

.details-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: baseline;
    justify-content: center;

    .detail {
        width: 48%;
        margin: 1%;

        @include media(">phone", "<desktop") {
            width: 100%;
            margin: 1%;
        }
    }
}

