$ourPlanet: #668A53;
$ourPlanet-lighter: #DDE5D8;
$ourPlanet-lightest: #F4F9F1;
$ourPlanet-follow: #0B330A;

$artAndCulture: #B99142;
$artAndCulture-lighter: #EFE6D4;
$artAndCulture-lightest: #FBF5F1;
$artAndCulture-follow: #5F4124;

$design: #81779A;
$design-lighter: #E3E0E8;
$design-lightest: #F7F6F8;
$design-follow: #363537;

$music: #4B908E;
$music-lighter: #D6E6E6;
$music-lightest: #F4F9F9;
$music-follow: #0B330A;

$hobbies: #A15A41;
$hobbies-lighter: #EADAD4;
$hobbies-lightest: #FDF4F0;
$hobbies-follow: #5F4124;

$news: #E13C3C;
$news-lighter: #F8D3D3;
$news-lightest: #FCF0EC;
$news-follow: #0B330A;

$famous: #C77287;
$famous-lighter: #F3DFE4;
$famous-lightest: #FFF9F9;
$famous-follow: #000000;

$sport: #6097D6;
$sport-lighter: #DBE8F6;
$sport-lightest: #FAFDFF;
$sport-follow: #5E8AB4;

$health: #60B6C3;
$health-lighter: #E0F0F2;
$health-lightest: #F9FFFF;
$health-follow: #000000;

$foodAndDrinks: #D36F1C;
$foodAndDrinks-lighter: #F5DFCC;
$foodAndDrinks-lightest: #FDF9F0;
$foodAndDrinks-follow: #5F4124;

$transport: #778393;
$transport-lighter: #E0E3E7;
$transport-lightest: #F2F3F4;
$transport-follow: #272727;

$techAndScience: #939289;
$techAndScience-lighter: #EAEAE8;
$techAndScience-lightest: #F8F8F8;
$techAndScience-follow: #5F4124;

$junior: #E7980E;
$junior-lighter: #FDEBCA;
$junior-lightest: #FFFDF1;
$junior-follow: #0B330A;

$community: #C193C6;
$community-lighter: #f6d2f6;
$community-lightest: #f9e6f9;
$community-follow: #0B330A;

$military: #506149;
$military-lighter: #D8DCD6;
$military-lightest: #F2F2F2;
$military-follow: #0B330A;

$fashion: #d19ccc;
$fashion-lighter: #e5dce5;
$fashion-lightest: #fcfcfc;
$fashion-follow: #a580a8;

$mainColor : #585858;
$brandColor: #0caf95;
$brandColorLight: #95cec5;


:export {
  planet: $ourPlanet;
  planet_lighter: $ourPlanet-lighter;
  planet_lightest: $ourPlanet-lightest;
  planet_follow: $ourPlanet-follow;


  artsCulture: $artAndCulture;
  artsCulture_lighter: $artAndCulture-lighter;
  artsCulture_lightest: $artAndCulture-lightest;
  artsCulture_follow: $artAndCulture-follow;

  design: $design;
  design_lighter: $design-lighter;
  design_lightest: $design-lightest;
  design_follow: $design-follow;

  music: $music;
  music_lighter: $music-lighter;
  music_lightest: $music-lightest;
  music_follow: $music-follow;

  hobby: $hobbies;
  hobby_lighter: $hobbies-lighter;
  hobby_lightest: $hobbies-lightest;
  hobby_follow: $hobbies-follow;

  news: $news;
  news_lighter: $news-lighter;
  news_lightest: $news-lightest;
  news_follow: $news-follow;

  famous: $famous;
  famous_lighter: $famous-lighter;
  famous_lightest: $famous-lightest;
  famous_follow: $famous-follow;

  sport: $sport;
  sport_lighter: $sport-lighter;
  sport_lightest: $sport-lightest;
  sport_follow: $sport-follow;

  health: $health;
  health_lighter: $health-lighter;
  health_lightest: $health-lightest;
  health_follow: $health-follow;

  foodDrink: $foodAndDrinks;
  foodDrink_lighter: $foodAndDrinks-lighter;
  foodDrink_lightest: $foodAndDrinks-lightest;
  foodDrink_follow: $foodAndDrinks-follow;

  transport: $transport;
  transport_lighter: $transport-lighter;
  transport_lightest: $transport-lightest;
  transport_follow: $transport-follow;

  techScience: $techAndScience;
  techScience_lighter: $techAndScience-lighter;
  techScience_lightest: $techAndScience-lightest;
  techScience_follow: $techAndScience-follow;

  junior: $junior;
  junior_lighter: $junior-lighter;
  junior_lightest: $junior-lightest;
  junior_follow: $junior-follow;

  community: $community;
  community_lighter: $community-lighter;
  community_lightest: $community-lightest;
  community_follow: $community-follow;

  military: $military;
  military_lighter: $military-lighter;
  military_lightest: $military-lightest;
  military_follow: $military-follow;

  fashion: $fashion;
  fashion_lighter: $fashion-lighter;
  fashion_lightest: $fashion-lightest;
  fashion_follow: $fashion-follow;

  mainColor: $mainColor;
  brandColor: $brandColor
}

