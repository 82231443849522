@import "../../assets/globalVariables.module.scss";
@import "../../assets/media-queries-breakpoints.scss";

.search-bar-container {
    margin: 0 auto; 
    display: 'flex';
    align-items: 'center'; 

    @include media(">LGdesktop") {
        width: 60%;
    }

    @include media(">desktop", "<LGdesktop") {
        width: 75%;
    }

    @include media(">smalldesktop", "<desktop") {
        width: 80%;
    }

    @include media(">tablet", "<smalldesktop") {
        width: 85%;
    }

    @include media(">phone", "<tablet") {
        width: 90%;
    }
}

.expanded-search-container {
    display: inline-flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    height: 30vh;
    padding-top: 2%;
    padding-bottom: 2%;

    @include media(">LGdesktop") {
        width: 60%;
        height: 25vh;
    }

    @include media(">desktop", "<LGdesktop") {
        width: 75%;
        height: 30vh;
    }

    @include media(">smalldesktop", "<desktop") {
        width: 80%;
        height: 35vh;
    }

    @include media(">tablet", "<smalldesktop") {
        flex-direction: column;
        width: 85%;
        height: 50vh;
    }

    @include media(">phone", "<tablet") {
        flex-direction: column;
        width: 90%;
        height: 55vh;
    }

    @include media("<phone") {
        width: 95%;
        height: 60vh;
    }

    .advanced-search-first-column {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-evenly;
        margin: 1% auto;
        
        @include media(">tablet", "<smalldesktop") {
            width: 75%;
        }

        @include media(">phone", "<tablet") {
            width: 85%;
        }

        .center-radio-buttons {
            justify-content: center;
        }

        .textfield-label {
            width: 20%;
            text-align: left;
        }

        .text-field-content-from {
            color: black;
            background-color: white !important;
        }
        .text-field-content-from-selected {
            color: black;
            background-color: #bfbfbd !important;
        }

        .text-field-location {
            width: 50%;
            text-align: left;
            margin-left: 10px;
        }
        .text-field-no-near-me {
            width: 64%;
            text-align: left;
            margin-left: 10px;
        }
        .radio-buttons-search {
            display: flex;
        }
    }

    .advanced-search-channels-column {
        width: 50%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin: 1% auto;
        gap: 15px;

        @include media(">tablet", "<smalldesktop") {
            width: 75%;
        }

        @include media(">phone", "<tablet") {
            width: 85%;
        }
    
        .advanced-search-second-column {
            @extend .advanced-search-first-column;
            width: 60%;

            .textfield-label {
                width: 30%;
                text-align: right;
                color: black;
            }

            .text-field {
                width: 80%;
                text-align: left;
            }
        }

        .advanced-search-third-column {
            @extend .advanced-search-first-column;
            overflow: auto;
            width: 35%;
            position: relative;
            height: 25vh;
            justify-content: flex-start;
            display: flex;

            .channel-button-search-bar {
                font-size: 0.8rem;
                line-height: 1;
                color: black;
                width: 140px;
                display: block;
                text-align: left;
                margin: 3% auto;
                border-radius: 15px;
                text-transform: none;
                font-size: revert;

                &:hover {
                    transition: 0.8s;
                    color: white;
                }
            }
        }
    }
}

.expanded-search-button-section {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    height: 5vh;

    @include media(">LGdesktop") {
        width: 60%;
    }

    @include media(">desktop", "<LGdesktop") {
        width: 75%;
    }

    @include media(">smalldesktop", "<desktop") {
        width: 80%;
    }

    @include media(">tablet", "<smalldesktop") {
        width: 85%;
    }

    @include media(">phone", "<tablet") {
        width: 90%;
    }

    .clear-button {
        font-family: "DM Sans Medium", sans-serif;
        color: #464646;
        padding: 8px 16px;
        line-height: 1.75;
        background-color: white;
        border-radius: 15px;
        border: 0;
        cursor: pointer;
        margin: 8px;
        display: inline-flex;
        outline: 0;
        position: relative;
        text-transform: none !important;
    }
}

.planet-search {
    @extend .channel-button-search-bar;
    background-color: $ourPlanet-lighter !important;

    &:hover {
        background-color: $ourPlanet !important;
    }
}

.artsCulture-search {
    @extend .channel-button-search-bar;
    background-color: $artAndCulture-lighter !important;

    &:hover {
        background-color: $artAndCulture !important;
    }
}

.styles-search {
    @extend .channel-button-search-bar;
    background-color: $design-lighter !important;

    &:hover {
        background-color: $design !important;
    }
}

.music-search {
    @extend .channel-button-search-bar;
    background-color: $music-lighter !important;

    &:hover {
        background-color: $music !important;
    }
}

.hobby-search {
    @extend .channel-button-search-bar;
    background-color: $hobbies-lighter !important;

    &:hover {
        background-color: $hobbies !important;
    }
}

.military-search {
    @extend .channel-button-search-bar;
    background-color: $military-lighter !important;

    &:hover {
        background-color: $military !important;
    }
}

.news-search {
    @extend .channel-button-search-bar;
    background-color: $news-lighter !important;

    &:hover {
        background-color: $news !important;
    }
}

.famous-search {
    @extend .channel-button-search-bar;
    background-color: $famous-lighter !important;

    &:hover {
        background-color: $famous !important;
    }
}

.sport-search {
    @extend .channel-button-search-bar;
    background-color: $sport-lighter !important;

    &:hover {
        background-color: $sport !important;
    }
}

.health-search {
    @extend .channel-button-search-bar;
    background-color: $health-lighter !important;

    &:hover {
        background-color: $health !important;
    }
}

.transport-search {
    @extend .channel-button-search-bar;
    background-color: $transport-lighter !important;

    &:hover {
        background-color: $transport !important;
    }
}

.foodDrink-search {
    @extend .channel-button-search-bar;
    background-color: $foodAndDrinks-lighter !important;

    &:hover {
        background-color: $foodAndDrinks !important;
    }
}

.techScience-search {
    @extend .channel-button-search-bar;
    background-color: $techAndScience-lighter !important;

    &:hover {
        background-color: $techAndScience !important;
    }
}

.community-search {
    @extend .channel-button-search-bar;
    background-color: $community-lighter !important;

    &:hover {
        background-color: $community !important;
    }
}

.junior-search {
    @extend .channel-button-search-bar;
    background-color: $junior-lighter !important;

    &:hover {
        background-color: $junior !important;
    }
}
