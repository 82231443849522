@import "../../assets/media-queries-breakpoints.scss";

.search-result-title {
    height: 15vh;
    font-size: 1.5rem;
    color: gray;
    vertical-align: middle;
    text-align: center;
    line-height: 15vh;
}

.search-results-container{
    display:flex;
    width: 98%;
    margin: 1%;
}

.search-results {
    width: 85%;
    margin: 1%;
    position: relative;

    .loading-overlay {
        position: absolute;
        background: white;
        opacity: 0.75;
        left: 0px;
        z-index: 25;
        width: 100%;
        height: 100%;
    }
}

.ads-container {
    width: 15%;
}

.no-results-container {
    font-family: 'DM Sans Medium', sans-serif;
    color: #464646;
    line-height: 1.5;
    display: inline-flex;
    outline: 0;
    flex-direction: column;

    .search-icon {
        width: 100%;
        margin: 0 auto;
    }

    .heading {
        font-size: 1.375rem;
        text-align: center;
    }

    .suggestions-heading {
        font-size: 1.15rem;
        text-align: left;
        margin: 0;
    }

    .suggestions {
        text-align: left;
        font-size: 1rem;
        align-items: flex-start;
    }
}
