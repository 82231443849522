@import '../../assets/globalVariables.module.scss';

.register-wraper-container
{
	
	max-width: 350px;
	height: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	font-family: Arial, Helvetica, sans-serif;
	.close-button {
		border: none;
		outline: none;
		width: 25px;
		height: 25px;
		margin-left: auto;
		margin-right: 20px;
		margin-top: 10px;
		background: white;
		cursor: pointer;
		.icon-button {
			color: $brandColor;
			font-size: 1rem;
		}
	}
	h1{
		text-align: center;
		color:$brandColor;
		margin: 2rem 0;
		font-weight: 300;
	}
	.sign-in-buttons
	{
		height: 100%;
		margin-bottom: 40px;
		.button-wraper
		{
			display: flex;
			flex-direction: column;
			justify-content: center;
			margin-bottom: 30px;
			.label,.register-label
			{
				text-align: center;
				margin: auto 20px;
			}
			.register-label{
				font-weight: bold;
			}
			.button-styles
			{
				font-family: 'DM Sans Medium', sans-serif;
				color:$brandColor;
				padding: 5px 16px;
				font-size: 1rem;
				font-weight: bold;
				line-height: 1.75;
				background-color: white;
				border: 0;
				cursor: pointer;
				margin-top: auto;
				margin-left: 30px;
				display: inline-flex;
				outline: 0;
				border-radius: 50px;
				margin: 10px auto;
				-webkit-box-shadow: 0px 3px 7px 2px rgba(0, 0, 0, 0.2);
				-moz-box-shadow: 0px 3px 7px 2px rgba(0, 0, 0, 0.2);
				box-shadow: 0px 3px 7px 2px rgba(0, 0, 0, 0.2);
			}
			.button-styles:hover
			{
				color:white;
				background-color: $brandColor;
			}
		}
		

	}
}
