@import '../../assets/globalVariables.module.scss';
.change-password {
	width: 380px;
	display: flex;
	height: auto;
	flex-direction: column;
	background-color: white;
	text-align: center;
	.title {
		margin: 30px 0;
		color: $brandColor;
		font-size: 25px;
	}
	.error-box {
		font-size: 14px;
		text-align: center;
		color: red;
	}
	.terms {
		color: black;
		font-size: 15px;
		margin: 10px auto;
	}
	.change-password-form {
		margin: auto;
	}
	.submit-button {
		width: 180px;
		height: 35px;
		letter-spacing: 0.5px;
		line-height: 35px;
		padding: 0 20px 0 20px;
		font-size: 10px;
		background-color: $brandColor;
		color: white;
		font-family: 'Open Sans Condensed';
		border-radius: 25px;
		margin-top: 30px;
		margin-left: auto;
		margin-right: auto;
		cursor: pointer;
		display: flex;
		justify-content: center;
	}
}
