@import '../../assets/media-queries-breakpoints.scss';

.sidebar-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 98%;
  margin-left: 1%;
  margin-right: 1%;

  .no-drawer-container {
    width: 0;
  }

  .drawer-container {
    width: 15%;
    font-size: 1.3rem;
    span{
      font-family: "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "sans-serif";
      font-size: 1.1rem;
    }
    @include media(">phone", "<smalldesktop") {
      width: 20%;
      font-size: 0.8rem;
    }

    @include media(">smalldesktop", "<desktop") {
      font-size: 1rem;
    }

    @include media(">LGdesktop") {
      width: 13%;
    }
  }

  .content-container {
    width: 60%;

    @include media(">phone", "<smalldesktop") {
      width: 50%;
    }
  }

  .content-container-no-local {
    width: 70%;

    @include media(">phone", "<smalldesktop") {
      width: 60%;
    }
  }

  .content-container-no-local-no-sidebar {
    width: 85%;

    @include media(">phone", "<smalldesktop") {
      width: 75%;
    }
  }

  .trending-component{
    width: 15%;

    @include media(">LGdesktop") {
      width: 12%;
    }
  }

  .hide{
    display: none;
  }

  .show-right-icon{
    display: block;
    background: white;
    cursor: pointer;
  }

  .show-left-icon{
    display: block;
    background: transparent;
    cursor: pointer;
  }


}
