.container{
    display: flex !important;
    flex-direction: column !important;
    margin: 0 0.2rem !important;
    border: none !important;
    box-shadow: none !important;
    &:hover .view-set-button{
        display: block;
    }
}

.card__time {
    font-size: 12px;
    font-weight: 700;
    color: #AD7D52;
    vertical-align: middle;
}

.card__img {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin: auto;
    display: block;
}

.card__info-hover {
    width: 90%;
    position: absolute;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    opacity: 0;
    top: 20px;

        .icon-button {
            font-size: 0.6rem;
            line-height: 1.5;
            left: 0;
            outline: 0;
            width: 1em;
            height: 3.5em;
            
            .svg-inline--fa {
                font-size: 1.175rem;
                position: relative;
                top: 5.5px;
                right: 11px;
            }
        }

    .card__clock-info {
        width: auto;
    }
}

.card {
  transition: all .4s cubic-bezier(0.175, 0.885, 0, 1);
  background-color: #fff;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 420px;
  min-height: 160px;
  overflow: hidden;
  box-shadow: 0px 13px 10px -7px rgba(0, 0, 0,0.1);

  &:hover {
    box-shadow: 0px 30px 18px -8px rgba(0, 0, 0,0.1);
    transform: scale(1.10, 1.10);

    .card__img {
        transition: 0.4s all ease-out;
        opacity: 0.2;
    }

    .card__info__default {
        visibility: hidden;
        background-color: transparent;
        transition: all ease-out;
    }
    
    .card__info {
        visibility: visible;
        transform: scale(1, 1);
        background-color: transparent;
        transition: 0.3s all ease-out;
    }
    
    .card__info-hover {
        z-index: 24;
        opacity: 1;
    }
  }
}

.card__info__default {
    z-index: 2;
    width: 100%;
    position: absolute;
    bottom: 0;
    font-size: 14px;
    text-overflow: ellipsis;
    height: auto;
    background-color: #fff;
}

.card__title {
    font-size: 14px;
    margin: 5px 10px;
    font-family: "Open Sans Condensed";
    text-overflow: ellipsis;
}

.card__info {
    visibility: hidden;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    z-index: 2;
    width: 90%;
    height: 80%;
    position: absolute;
    bottom: 0;
    background-color: #fff;

    .card__navigation {
        font-family: "DM Sans Medium", sans-serif;
        font-size: 11px;
        text-align: left;
        font-weight: 500;
        margin: 2px 10px;
        color: #272727;
    }

    .card__location {
        font-family: "DM Sans Medium", sans-serif;
        font-size: 11px;
        text-align: left;
        font-weight: 500;
        margin: 2px 10px;
        color: #272727;

        .infotip-button {
            font-size: 13px;
            text-align: left;
            width: 100%;
            padding: 5px 0;
            opacity: 1;
            border: none;
            text-decoration: none;
        }
    }

    .card__description {
        font-family: "DM Sans Medium", sans-serif;
        font-size: 13px;
        text-align: justify;
        text-overflow: ellipsis;
        overflow: hidden;
        margin: 5px 10px;
        color: #272727;
    }
    
    .card__by {
        font-size: 12px;
        font-family: "DM Sans Medium", sans-serif;
        font-weight: 500;
        margin-bottom: 5px;
        margin-right: 10px;
        text-align: right;

        .card__author {
            font-weight: 600;
            text-decoration: none;
            color: #AD7D52;
        }
    }
}


// OLD STYLES

.image-container {
    display: inline-block;
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: relative;
    padding-bottom: 0.2rem;
    
    &:hover .icons {
        display: block;
        transition: 1s;
        z-index: 30;
    }
    &:hover {
        z-index: 10;
        transition: 1s;
    }

    &:hover .image-infotip {
        display: block;
        transition: 1s;
        width: inherit;
    }

    &:hover .image-infotip-headline{
        display: none;
    }

    .icons {
        opacity: 0.8;
        display: none;
        position: absolute;
        top: 2%;
        left: 2%;
        
    }
    .item-image {
        width: 100%;
        height: 100%;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            &:hover {
                z-index: 10;
            }

            &:hover .item-icons {
                display: block;
                transition: 1s;
                z-index: 11;
            }
        }
    }
}

.content-headline-none {
    display: none;
}


.icon-button {
    // background: white;
    font-size: 0.6rem;
    line-height: 1.75;
    font-weight: 500;
    font-family: "DM Sans Medium", sans-serif;
    display: inline-flex;
    outline: 0;
    width: 1em;
    height: 3.5em;
    .svg-inline--fa {
        font-size: 1.175rem;
        position: relative;
        top: 5.5px;
        right: 11px;
    }
}
.buttonContainer {
    .image-infotip {
        display: none;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: #fbfbfb;
        opacity: 0.8;
        color: black;
        padding-bottom: 2%;

        .infotip-container {
            font-size: 17px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            width: 100%;
            .title {
                grid-column: span 2;
                font-size: 17px;
                padding: 5px 0;
                text-overflow: ellipsis;
            }

            .infotip-left {
                display: grid;
                grid-column: span 1;
                align-items: center;
                font-weight: 300;

                .description {
                    grid-column: span 2;
                    font-size: 12px;
                    word-break: break-word;
                    text-overflow: ellipsis;
                }

                .channel-location {
                    grid-column: span 2;
                    font-size: 12px;
                }
            }

            .infotip-right {
                font-size: 12px;
                display: grid;
                grid-column: span 1;
                align-items: center;
                font-weight: 300;
                padding-bottom: 2%;

                .author {
                    padding-bottom: 3px;
                }
                .location-and-date {
                    grid-column: span 1;
                    display: grid;
                    .location {
                        padding-top: 3px;
                    }

                    .date-added {
                        padding: 3px 0;
                    }
                }
            }
        }

        .infotip-button {
            font-size: 13px;
            text-align: left;
            width: 100%;
            padding: 5px 0;
            opacity: 1;
            border: none;
            text-decoration: none;
        }
    }
    .image-infotip-headline {
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background: #f2f2f2;
        opacity: 0.8;
        color: black;
        padding-bottom: 2%;
    }
}

.cart-icon-button{
    width: 25px;
    height: 25px;
    border-radius: 30px;
    padding: 0.5rem;
    position: absolute;
    bottom: 10px;
    right: 3px;
    color: white;
    cursor: pointer;
}

.view-set-button{
    position: absolute;
    padding: 0.5rem 0.8rem;
    border-radius: 15px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    display: none;
}