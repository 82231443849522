@import '../../assets/globalVariables.module.scss';
@import '../../assets/media-queries-breakpoints.scss';

.page-wrapper
{
    width: 60%;
    position: relative;
    margin: 1% auto;

    @include media("<phone") {
        width: 100%;
    }

    @include media(">phone", "<tablet") {
        width: 90%;
    }

    @include media(">tablet", "<desktop") {
        width: 70%;
    }

    @include media(">desktop", "<LGdesktop") {
        width: 50%;
    }
   
    .dropdown-wrapper
    {
        margin: 0 auto;
        width: 80%;
        background: #e9e9e9;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        .headline
        {  
            padding: 10px 50px;
            color:white;
            background:$brandColor;
            border-top-right-radius:10px;
            border-top-left-radius:10px;
        }
        .accordion-container
        {
            display:flex;
            flex-direction: column;
            justify-content: center;
            width: 85%;
            margin: 0px auto;
            background: #e9e9e9;
            padding-bottom:10px;
        }
    }
}
#panel1c-header
{
    border-top-right-radius:10px;
    border-top-left-radius:10px;
    background: gray;
    width: auto;
    color: white;
    text-align: center;
    font-weight: bold;
    .MuiSvgIcon-root
    {
        color:white;
    }
}
.title
{
    margin:auto;
}
.custom-accordion
{
    box-shadow: none;
    border-bottom: 1px solid gray !important;
    border-radius: 0;
    .MuiSvgIcon-root
    {
        color:gray;
    }
}
.inner-accordion
{
    padding: 0 !important;
    .panel1c-content
    {
        padding-left: 30px;
    }
    
}
.inner-accordion-editors {
    background-color: #e9e9e9 !important;
    color: black !important;
}
.years
{
    float: left;
    color: gray;
    background: inherit;
    border: none;
    outline: none;
    font-size: 1.1rem;
    margin-left: 10px;
    margin-bottom: 10px;
    max-height: 200px;
}
.MuiFormHelperText-root
{
    color:red !important;
}
.helper-text-select
{
    color:red !important;
    font-size: 0.75rem;
    text-align: left;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    letter-spacing: 0.03333em;
}

.dropdown-component {
    width: 100%;
    text-align: left;
    margin-top:5px;
}

.gray-color
{
    color:gray;
    display: flex;
    margin-top: 2px;
}
.MUI-focused
{
    color:gray;
}

.about-you-container
{
    display:flex;
    flex-direction: row;
    @include media("<desktop") {
        flex-direction: column;
        align-items: center;
    }

    .profile-pic-container
    {
        width: 50%;
        h4{
            text-align: center;
        }
        @include media("<desktop") {
            width: 100%;
        }
    }
    .dropzone-container
    {
        
        width:30vh;
        height:200px;
        margin: 0 auto;
    
        @include media("<desktop") {
            width: 90%;
            height:200px;
            margin: 0 auto;
        }
    
        @include media(">desktop", "<LGdesktop") {
            width: 80%;
        }
    }
    .sun-editor
    {
        border-radius: 15px;
        height: 100%;
        background: #e9e9e9;
        border: 1px solid gray;
        .se-toolbar 
        {
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
            outline:none;
            color:gray;
            background: #dfdfdf;
        }
        .se-wrapper-inner
        {
            background: #e9e9e9 !important;
        }
        .se-resizing-bar
        {
            display:none;
        }
    }
}
.text-field-container
{
    display: flex;
    flex-direction: column;
    height: inherit;
    width: 45%;
    @include media("<desktop") {
        width: 95%;
        margin: 0 auto;
    }
    #mui-rte-root
    {
        border: 1px solid gray;
        height: 100%;
        border-radius: 15px;
    }

    #mui-rte-container
    {
        margin-top:0;
        background: #dfdfdf;    
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        padding:5px 0px;
        .MuiIconButton-root
        {
            padding: 5px 1px !important;
        }
        #mui-rte-editor-container
        {
            display: display !important;
            background-color: #e9e9e9;
        }
    }
}

.empty-fields-error
{
    padding:0 50px;
    color:gray;
    .empty-field
    {
        color:red;
        font-weight: bold;
    }
}
//---------------------------------------STEP TWO-------------------------------------------------------------------
.step-two-container
{
    text-align: left;
    width: 95%;
    margin: auto;
    font-family: 'DM Sans Medium', sans-serif;
    .step-two-headline
    {
        margin: 10px 0px;
        font-size: 1.375rem;
        line-height: 1.75;
        font-weight: 500;
    }
    .step-two-options
    {
        border-radius:15px;
        box-shadow: 0px 3px 7px 0px rgba(0,0,0,0.2);
        margin-bottom: 30px;
        .options-head
        {
            background: #808080;
            color: white;
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
            padding: 1px 0px;
            h3{
                text-align: center;
            }
        }
        .options-content{
            display: flex;
            flex-direction: row;
            .options-visualisation-wrapper
            {
                padding-left:30px;
                width: 45%;
                
            }
            .text-field-wrapper
            {
                width: 55%;
                padding-left: 30px;
                padding-right: 30px;
                .MuiFormControl-root
                {
                    width: 100%;
                }
                .MuiFormLabel-root
                {
                    font-weight: bold;
                    margin:auto;
                    left: 40%;
                }
            }
        }
  
    }
    .MuiAccordion-root
    {
        border-radius:15px;
        box-shadow: 0px 3px 7px 0px rgba(0,0,0,0.2);
        margin-bottom: 30px !important;
        .options-content
        {
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
        }
    }
    .Mui-expanded
    {
        min-width: 48px !important;
    }
    .MuiAccordion-rounded
    {
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;
    }
    #panel1c-header
    {
        border-top-left-radius: 15px;
        border-top-right-radius:15px;
    }
    .accordion-header-container
    {
        display: flex;
        width: 100%;
        .title{
            position: relative;
            left: 150px;
        }
        .searchfield
        {
            position: relative;
            z-index: 1;
            width: 250px;
        }
    }
}

.channels-visualisation-wrapper
{
    padding:30px;
    padding: 30px;
    background: white;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    .subchannel-rows
    {
        text-align: center;
        padding: 10px 0px;
        &:nth-child(even)
        {
            background-color: #e9e9e9;
        }
    }
}
.subchannel-visualisation-wrapper
{
    @extend .channels-visualisation-wrapper;
    padding:0;
}
.pill-button
{
    background: white;
    padding: 0px 16px;
    font-size: 1rem;
    line-height: 1.75;
    font-weight: 500;
    font-family: 'DM Sans Medium', sans-serif;
    display: inline-flex;
    outline: 0;
    border-radius: 25px;

    &:active
    {
        transform: translateY(2px) translateX(2px);
    }
 
    .button-text
    {
        display: flex;
        padding-top: 3px;
    }
    .keyword-delete
    {
        cursor: pointer;
    }
    .channel-add
    {
        color:inherit;
        cursor: pointer;
        margin-left: 5px; 
        &:active
        {
            transform: translateY(2px);
        }
    }
   
}
.keyword-button
{
    @extend .pill-button;
    border: 2px solid black;
    cursor: default;
    padding: 0px 7px 0px 16px;
}
.channel-button-add
{
    @extend .pill-button;
    cursor: pointer;
    box-shadow: 1px 3px 7px 2px rgba(0, 0, 0, 0.2);
    border:0;
    padding: 0px 7px 0px 16px;
}

.foodDrink-add{
    @extend .pill-button;
    background-color: $foodAndDrinks;
    transition: 0.8s;
    color: white;
}

.techScience-add{
    @extend .pill-button;
    background-color: $techAndScience;
    transition: 0.8s;
    color: white;
}

.community-add {
    @extend .pill-button;
    background-color: $community;
    transition: 0.8s;
    color: white;
}

.junior-add {
    @extend .pill-button;
    background-color: $junior;
    transition: 0.8s;
    color: white;
}
.planet-add {
    @extend .pill-button;
    background-color: $ourPlanet;
    transition: 0.8s;
    color: white;
}

.styles-add {
    @extend .pill-button;
    background-color: $design;
    transition: 0.8s;
    color: white;
}

.music-add{
    @extend .pill-button;
    background-color: $music;
    transition: 0.8s;
    color: white;
}

.hobby-add {
    @extend .pill-button;
    background-color: $hobbies;
    transition: 0.8s;
    color: white;
}

.military-add {
    @extend .pill-button;
    background-color: $military;
    transition: 0.8s;
    color: white;
}

.news-add {
    @extend .pill-button;
    background-color: $news;
    transition: 0.8s;
    color: white;
}

.famous-add {
    @extend .pill-button;
    background-color: $famous;
    transition: 0.8s;
    color: white;
}

.sport-add{
    @extend .pill-button;
    background-color: $sport;
    transition: 0.8s;
    color: white;
}

.health-add {
    @extend .pill-button;
    background-color: $health;
    transition: 0.8s;
    color: white;
}

.transport-add {
    @extend .pill-button;
    background-color: $transport;
    transition: 0.8s;
    color: white;
    
}

.artsCulture-add{
    @extend .pill-button;
    background-color: $artAndCulture;
    transition: 0.8s;
    color: white;
}

.fashion-add{
    @extend .pill-button;
    background-color: $fashion;
    transition: 0.8s;
    color: white;
}

.search-add {
    @extend .pill-button;
    background-color: black;
    transition: 0.8s;
    color: white;
}
//-------------------------------------------Page navigation------------------------------------------------------
.step-navigation{
    width: 100%;
    display: flex;
    justify-content: space-evenly;

    .next-page
    {
        @extend .pill-button;
        background: $brandColor;
        border:0;
        color:white;
        padding: 0px 7px 0px 16px;
        box-shadow: 1px 3px 7px 2px rgba(0, 0, 0, 0.2);
    }
    .back-button
    {
        @extend .pill-button;
        box-shadow: 1px 3px 7px 2px rgba(0, 0, 0, 0.2);
        border:0;
        padding: 0px 16px 0px 7px;
    }
}

//--------------------------------------------Page licensing-------------------------------------------------------
.licensing-page
{
    width:95%;
    margin-top: 10px;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    

    .plan-table-container
    {
        border-radius: 15px;
        width: 70%;
        margin: 30px auto;
        box-shadow: 1px 3px 7px 2px rgba(0, 0, 0, 0.2);

        .heading-licensing
        {
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            padding: 10px;
            background: #aaaaaa;
            color:white;
        }
        .plan-table
        {
            width: 100%;
            display: flex;
            flex-direction: column;
            .plan-table-header
            {
                display: flex;
                flex-direction: row;
                padding: 10px 0;
                div
                {
                    font-size: 0.9vw;
                    margin:auto;
                    width:25%;
                }
            }
            .plan-table-row
            {
                display: flex;
                flex-direction: row;
                padding: 10px 0;
                &:hover{
                    background: #aaaaaa!important;
                    color: white;
                }
                &:nth-child(even)
                {
                    background-color: #e3e5e5;
                };
                &:last-child
                {
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                }
                div
                {
                    font-size: 0.9vw;
                    margin:auto;
                    width:25%;
                }
            }
            .selected-tr
            {
                    background: $brandColor !important;
                    color: white !important;
            }
        }

    }

}
.buy-button
{
    @extend .pill-button;
    background: $brandColor;
    font-size: 1.3rem;
    font-weight: bold;
    border:0;
    color:white;
    padding: 1px 10px ;
    box-shadow: 1px 3px 7px 2px rgba(0, 0, 0, 0.2);
}
@media only screen and (max-width: 1200px) {
        .licensing-page
        {
            flex-direction: column !important;
        }
        .plan-table-container
        {
            width: 80% !important;
            margin-bottom: 30px !important;
        }
        .plan-table-row{
            div{
                font-size:1.5vw !important;
            }
        }
        .plan-table-header{
            div{
                font-size:1.5vw !important;
            }
        }
    }

@media only screen and (max-width: 960px)
{
    .plan-table-row{
        div{
            font-size:2.5vw !important;
        }
    }
    .plan-table-header{
        div{
            font-size:2.5vw !important;
        }
    }
}
#agreement-check
{
    padding-left: 0 !important;
    color: gray;
    text-align: left;
    float:left;
    margin: 12px 0;
}
.step-one-channels
{
    margin-top: 10px;
}

.onboarding-channel-buttons
{
    padding: 0px 16px !important;
}
