@import '../../assets/media-queries-breakpoints.scss';

.trending-container{
  display: flex;
  flex-direction: column;
  gap: 5px;

  .trendingTitle{
    color: #999;
    display: block;
    font-size: 1.2rem;
    text-align: center;
    padding-bottom: 1rem;
    font-weight: bold;

    @include media(">phone", "<smalldesktop") {
      font-size: 0.9rem;
    }

    @include media(">smalldesktop", "<desktop") {
      font-size: 1rem;
    }

    @include media(">LGdesktop") {
      font-size: 1.3rem;
    }
  }

  .trendingSpan {
    display: block;
    font-size: 0.9rem;
    text-align: left;
    cursor: pointer;

    @include media(">phone", "<smalldesktop") {
      font-size: 0.7rem;
    }

    @include media(">smalldesktop", "<desktop") {
      font-size: 0.9rem;
    }

    @include media(">LGdesktop") {
      font-size: 1.1rem;
    }
  }
}

.hide {
  display: none
}

.show {
  display: block;
  border : none;
  outline: none;
  background: white;
  cursor: pointer;
}
