.contact-container {
  padding: 1rem;
}
.contact-title{
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
}
.contact-form {
  width: 60%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .form-field {
    width: 100%;
  }
}

