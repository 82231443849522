@import '../../assets/globalVariables.module.scss';

.container
{
	font-family: 'DM Sans Medium', sans-serif;
	.banner-container {

			width: 1000px;
			height: auto;
			display: flex;
			flex-direction: row;
			background-repeat: no-repeat;
			background-size:cover;

			.button {
				width: 140px;
				height: 35px;
				letter-spacing: 0.5px;
				line-height: 35px;
				padding: 0 20px 0 20px;
				font-size: 10px;
				background-color: $brandColor;
				color: white;
				font-family: 'Open Sans Condensed';
				border-radius: 25px;
				margin-top: 30px;
				margin-bottom: 30px;
				margin-left: auto;
				margin-right: auto;
				cursor: pointer;
				display: flex;
				justify-content: center;
			}

			.select-label {
				display: inline-block;
				margin-right: 10px;
			}

			.error-box {
				font-size: 14px;
				text-align: center;
				color: red;
			}
			.signInButtons
			{
				display:flex;
				height: 100%;
				width: 47%;
				flex-direction:column;
				justify-content:left;
				background: rgba(225, 227, 226, 0.9);
			}
			#button-styles
			{
				float: left;
				margin-left: 10px;
			}
			.button-styles
			{
				margin:10px;
			}
			.buttonLabels
			{
				width:70%;
				margin: 55px auto;
				font-size:repeat(auto-fit,1.3rem);
			}
		}
	.head-container
	{
		width: 100%;
		display:flex;
		flex-direction:row;
		justify-content: center;
		border-bottom:1px solid #919192;

		.headline
		{
			width: 90%;
			text-align:center;
		}
		.close-button-banner {
			border: none;
			outline: none;
			font-size: 1.3rem;
			width: 30px;
			margin-left: auto;
			margin-top: 30px;
			background: white;
			cursor: pointer;
			.icon-button-banner {
				color: $brandColor;
			}
		}
	}
	.banner-container {
		height: auto;
		background-repeat: no-repeat;
		background-size:cover;
		display: flex;
		flex-direction: row;

		.sign-in-labels
		{
			padding-bottom: 40px;
			width: 47%;
			background: rgba(225, 227, 226, 0.9);
			.label-wraper
			{
				font-family: 'DM Sans Medium', sans-serif;
				font-size: 1em;

				margin: 40px 40px 40px 40px;
				.labels
				{
					margin-top: auto;
				}
			}
		}
		.sign-in-buttons
		{
			width: 53%;
			padding-top:30px;
			
			.button-wraper
			{
				margin: 40px;
				height:150px;
				.button-styles
				{
					font-family: 'DM Sans Medium', sans-serif;
					color:black;
					padding: 0px 16px;
					font-size: 1em;
					line-height: 1.75;
					background-color: white;
					border: 0;
					cursor: pointer;
					margin-top: auto;
					margin-left: 30px;
					display: inline-flex;
					outline: 0;
					border-radius: 50px;
					margin:auto
				}
			}


		}
	}
}


@media only screen and (max-width:560px){
	.container{
		.head-container{
			.headline{
				font-size: 0.6em;
			}
		}
		.banner-container{
			width:100%;
			.sign-in-labels{
				.label-wraper{
					margin:20px 10px 20px 10px;
					font-size: 0.8em;
				}
			}
			.sign-in-buttons{
				.button-wraper{
					font-size: 0.8em;
				}
			}
		}
	}
}

@media only screen and (max-width:1060px){
	.container{
		.banner-container{
			width:100%;
		}
	}
}