@import '../../assets/globalVariables.module.scss';

.dropzone-container {
    display: flex;
    flex-direction: column;
    height: 75vh;
    min-height: 120px;
    position: relative;
    transition: all .15s linear;
    border-radius: 15px;
    border: none;
    background-color: white;

    .preview-container {
        background-color: white;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        overflow-y: auto;
        margin: auto !important;
        width: 100%;

        .preview-box {
            display:flex;
            align-items:center;
            width: 140px;
            height: 140px;
            background: #fff;
            font-size: 14px;
            margin: auto;
            flex-direction: column;

            .image {
                height: 110px;
                width: 110px;
                object-fit: cover;
                border-radius: 4px;
            }
        }
    }

    .error-box {
        font-size: 18px;
        background-color: white;
        color: red;
    }

    .submit-label {
        display: flex;
        position: relative;
        margin: 30px auto;
        font-family: 'Helvetica', sans-serif;
        font-size: 20px;
        font-weight: 100;
        color: white;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;

        .label-text {
            cursor: pointer;
            margin: auto 0;
        }

        .upload-icon {
            width: 1em;
            height: 1em;
            vertical-align: bottom;
            margin: 0 12px;
        }
    }

    .dropzone-label {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Helvetica', sans-serif;
        font-size: 5px;
        font-weight: 300;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        cursor: pointer;

        .dropzone-inputLabel {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            font-family: 'Helvetica', sans-serif;
            font-size: 10px;
            font-weight: 300;
            color: $brandColor !important;
            -moz-osx-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;
            cursor: pointer;
        }

        .upload-icon {
            width: 2em;
            height: 2em;
            vertical-align: bottom;
            margin: 0 12px;
        }
    }
}