.edit-metadata-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between; 
        margin-bottom: 1rem;

    .content-vertical-bar {
        width: 20%;

        .vertical-bar-buttons {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 2%;
            margin: 2%;
        }

        .image-container {
            flex-shrink: 0;
            max-width: 100%;
            max-height: 100%;
        }
    }

    .content-image-metadata {
        width: 40%;

        .breadcrumb-container {
            display: inline-flex;
            align-items: center;
            justify-content: center;
        }

        .button-group {
            display: inline;
        }
    }
}

.content-set-label {
    text-align: left;
    margin: 18px;
    font-weight: bold;
}

.header-label {
    text-align: left;
    margin: 12px;
    line-height: 2;
    font-weight: bold;
}

.form-field-row {
    display: flex;
    margin: 3px;
    padding: 3px 4px;
}

.button-link {
    color: black;
    font-size: 0.7rem;
    float: right;
    margin: 5px 4px !important;
    
    &:hover {
        cursor: pointer;
        color: black;
    }
}

.accordion-details {
    width: 100%;
    border: 0;
    margin: 0;
    display: flex;
    padding: 0;
    position: relative;
    min-width: 0;
    flex-direction: column;
    vertical-align: top;
}

.tag-input-container {
    display: inline-block;
    width: 100%;

    .tag-input-container > * {
        margin: 15px 0;
    }
}