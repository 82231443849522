@import "../../assets//globalVariables.module.scss";

.content-headline {
  float: left;
}

.infinite-scroll-wrapper{  
  position: relative;

  .loading-overlay {
    position: absolute;
    background: white;
    opacity: 0.75;
    left: 0px;
    z-index: 25;
    width: 100%;
    height: 100%;
    overflow: auto;
  }
}

.no-results-container {
  font-family: 'DM Sans Medium', sans-serif;
  color: #464646;
  line-height: 1.5;
  display: inline-flex;
  outline: 0;
  flex-direction: column;

  .search-icon {
      width: 100%;
      margin: 0 auto;
  }

  .heading {
      font-size: 1.375rem;
      text-align: center;
  }

  .suggestions-heading {
      font-size: 1.15rem;
      text-align: left;
      margin: 0;
  }

  .suggestions {
      text-align: left;
      font-size: 1rem;
      align-items: flex-start;
  }
}

.pagination-container{
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 2rem;
}

.pagination-label{
  margin: auto 0;
  padding-right: 0.5rem;
}

.pagination-component{
  margin-left: 2rem;
}

.Mui-selected{
  color: white !important;
  font-weight: bold !important;
  height: auto !important;
  background-color: $brandColor !important;
 }

 .near-me-title{
  margin-top: 2rem;
  font-weight: bold;
  color: $brandColor;
  font-size: 1.5rem;
 }
.slider-container{
  height: auto;
}

 .slider {
  display: flex;
  align-items: center;
  width: 100%;
}

.slider-images-container {
  width: auto;
  max-height: 300px;
  display: flex;
  overflow: hidden;
}

.item {
  min-width: 200px;
  max-width: 200px;
  height: auto;
  margin-right: 0.3rem;
}

.full {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.left-arrow,
.right-arrow {
  position: absolute;
  top: 50;
  z-index: 1000;
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 100%;
  cursor: pointer;
  color: white;
  background-color: $brandColor;
  font-weight: bold;
}

.right-arrow{
  right: 0;
}

.image-skeleton{
  border-radius: 0 !important;
}

.no-nearby-content-warning{
  font-family: "DM Sans Medium", "sans-serif";
}