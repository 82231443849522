@import '../../assets/globalVariables.module.scss';

.channelButtonPicture
{
    position: absolute !important;
    opacity: 0.7;
    top: 40%;
    box-shadow: 0px 5px 7px 0px rgba(0,0,0,1) !important;
}
.buttonContainer
{
    width: 100%;
    display: flex;
    justify-content: center;
}
.item-container:hover .buttonContainer
{
    top: 18%;
}
.channel-button {
    font-family: 'DM Sans Medium', sans-serif;
    color: #464646;
    padding: 8px 16px;
    font-size: 1.375rem;
    line-height: 1.75;
    background-color: white;
    border: 0;
    cursor: pointer;
    margin: 8px;
    display: inline-flex;
    outline: 0;
    position: relative;
    border-radius: 25px;
    -webkit-box-shadow: 0px 3px 7px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 3px 7px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 3px 7px 0px rgba(0,0,0,0.2);
}

.planet {
    @extend .channel-button;

    &:hover {
        background-color: $ourPlanet;
        transition: 0.8s;
        color: white;
    }
}

.style {
    @extend .channel-button;

    &:hover {
        background-color: $design;
        transition: 0.8s;
        color: white;
    }
}

.music {
    @extend .channel-button;

    &:hover {
        background-color: $music;
        transition: 0.8s;
        color: white;
    }
}

.hobby {
    @extend .channel-button;

    &:hover {
        background-color: $hobbies;
        transition: 0.8s;
        color: white;
    }
}

.military {
    @extend .channel-button;

    &:hover {
        background-color: $military;
        transition: 0.8s;
        color: white;
    }
}

.news {
    @extend .channel-button;

    &:hover {
        background-color: $news;
        transition: 0.8s;
        color: white;
    }
}

.famous {
    @extend .channel-button;

    &:hover {
        background-color: $famous;
        transition: 0.8s;
        color: white;
    }
}

.sport {
    @extend .channel-button;

    &:hover {
        background-color: $sport;
        transition: 0.8s;
        color: white;
    }
}

.health {
    @extend .channel-button;

    &:hover {
        //color will be changed
        background-color: $health;
        transition: 0.8s;
        color: white;
    }
}

.transport {
    @extend .channel-button;

    &:hover {
        background-color: $transport;
        transition: 0.8s;
        color: white;
    }
}

.artsCulture {
    @extend .channel-button;

    &:hover {
        background-color: $artAndCulture;
        transition: 0.8s;
        color: white;
    }
}

.foodDrink {
    @extend .channel-button;

    &:hover {
        //color will be changed
        background-color: $foodAndDrinks;
        transition: 0.8s;
        color: white;
    }
}

.techScience {
    @extend .channel-button;

    &:hover {
        background-color: $techAndScience;
        transition: 0.8s;
        color: white;
    }
}

.community {
    @extend .channel-button;

    &:hover {
        background-color: $community;
        transition: 0.8s;
        color: white;
    }
}

.junior {
    @extend .channel-button;

    &:hover {
        //color will be changed
        background-color: $junior;
        transition: 0.8s;
        color: white;
    }
}